import Header from "./Header";
import React from "react";
import { Outlet } from 'react-router-dom';
import GoToTop from "../components/GoToTop";
import Sidebar from "./Sidebar";
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { resetMessage } from "../redux/actions/messageActions";
const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Layout({ resetMessage, message }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    resetMessage();
  };
  return (
    <div className="body-wrapper">
      <div className="sidebar">
         <Sidebar />
      </div>
      <div className="bodyWrap">
        <Header />
        <div className="mainContentWrap">
          <Outlet />
        </div>
      </div>


      {/* <Footer /> */}
      <GoToTop />
      <Snackbar open={message.message !== ''} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={`${message.error ? 'error' : 'success'}`} sx={{ width: '100%' }}>
          {message.message}
        </Alert>
      </Snackbar>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    message: state.message
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetMessage: () => dispatch(resetMessage())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
