import { ERROR, SUCCESS, RESET_MESSAGE } from "../constants";

const initialState = {
    error: false,
    success: false,
    message: ''
};

const messageReducer = (state = initialState, { type, payload }) => {
    switch(type) {
        case ERROR:
            state = {
                error: true,
                success: false,
                message: payload ? payload : 'Something went wrong! Please try again later '
            }
            return {...state}
        case SUCCESS:
            state = {
                error: false,
                success: true,
                message: payload
            }
            return {...state}
        case RESET_MESSAGE:
            return initialState;
        default:
            return state;
    }
}

export default messageReducer;