import { ERROR, SUCCESS, RESET_MESSAGE } from "../constants";

export const error = (payload) => {
    return {
        type: ERROR,
        payload
    }
}

export const success = (payload) => {
    return {
        type: SUCCESS,
        payload
    }
}

export const resetMessage = () => {
    return {
        type: RESET_MESSAGE
    }
}