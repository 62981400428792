import LoaderGif from '../assets/images/loader.gif'; 

function Loader() {
  return (
    <div className='loader-wrap '>
        <img src={LoaderGif} alt='Loader Gif' width='300px' />
    </div>
  )
}

export default Loader;
