import { default as Axios } from 'axios';
import store from '../redux/store';
import { error, success } from '../redux/actions/messageActions';

const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if(token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
  if(response?.data?.status && response?.data?.message) {
    store.dispatch(success(response?.data?.message));
  } else if(!(response?.data?.status) && !response?.data?.message) {

  } else if(!(response?.data?.status || response?.data?.results?.status)) {
    store.dispatch(error(response.data.message || 'Something went wrong. Please try again later'));
  }
  return response;
}, function (err) {
  if(err.response?.data?.code === 'token_not_valid' || err.response?.data?.code === 'user_not_found') {
    store.dispatch(error('Session Expired. Please login again'));
    localStorage.removeItem('token');
    setTimeout(() => {
      window.location = '/';
    }, 2000);
  } else if(!err?.response?.data?.status) {
    store.dispatch(error(err?.response?.data?.message || 'Something went wrong. Please try again later'));
  } else {
    err.response?.data?.message !== 'Invalid page.' && store.dispatch(error(err?.response?.data?.message || 'Something went wrong. Please try again later'));
  }
});

export const getCityAndState = (pincode) => {
  return Axios.get(`https://api.eyemyeye.com/api/accounts/address/check_pincode/?pincode=${pincode}`);
}

export default axios;