import axios from './axiosConfig';

export const adminLogin = (data)=>{
    return axios.post('/accounts/admin-login',data)
}

export const adminLogout = ()=>{
    return axios.get('/accounts/admin-logout')
}



export const claimReportDownload = (params) => {
    return axios.get('/admin/retailers-claim-report', { params: params, responseType: 'blob' })
}

export const isLoggedIn = () => {
    return !!localStorage.getItem('token');
}

export const uploadRetailerProducts= (data)=>{
    return axios.post('/admin/upload-products',data)
}

export const uploadRetailerLens= (data)=>{
    return axios.post('/admin/upload-lens',data)
}

export const addCoupon = (data)=>{
    return axios.post('/admin/offers',data)
}

export const viewCouponListing = ()=>{
    return axios.get('/admin/offers')
}

export const viewCoupon = (params)=>{
    return axios.get('/admin/offers',{params})
}

export const editCoupon = (data)=>{
    return axios.put('/admin/offers',data)
}

export const toggleCoupon =(data)=>{
    return axios.put('/admin/add-offers',data)
}

export const addRetailer = (data)=>{
    return axios.post('/admin/retailers',data)
}

export const editRetailer = (data)=>{
    return axios.patch('/admin/retailers',data)
}

export const showRetailerListing = ()=>{
    return axios.get('/admin/retailers') 
}

export const viewRetailer = (params)=>{
    return axios.get('/admin/get-retailer',{params})
}

export const toggleRetailer = (data)=>{
    return axios.put('/admin/retailers', data)
}

export const getRetailerEmailAddress = (params)=>{
    return axios.get('/admin/notifications',{params})
}

export const getNotifications = () => {
    return axios.get('/admin/notifications');
}

export const createNotification = (data) => {
    return axios.post('/admin/notifications', data);
}

export const getEyeTestReport = (params) =>{
    return axios.get ('/admin/quiz',{params})
}

export const getStoreNames = (params)=>{
    return axios.get('/admin/add-offers',{params})
}

export const getCategory = ()=>{
    return axios.get('/admin/category-and-brands')
}

export const getBrandsOfCategory = (params) =>{
    return axios.get('/admin/category-and-brands',{ params })
}

export const addOtherRetailers = (data) =>{
    return axios.post('/admin/retailer-management',data)
}

export const downloadList = (from_date, to_date, store_ids, is_partial) => {
    return axios.get('/admin/export-order-report', { params: { to_date, from_date, store_ids, is_partial }, responseType: 'blob' })
}

export const addKnowledgeVideo = (data)=>{
    return axios.post('/admin/video',data)
}

export const showKnowledgeBase = ()=>{
    return axios.get('/admin/video')
}

export const getOrderReport = () => {
    return axios.get('/admin/order-report')
}

export const getOrderDetails = (params) => {
    return axios.get('/admin/order-report', { params })
}

export const getDashboardData = () => {
    return axios.get('/admin/dashboard');
}

export const downloadNotification = (params) => {
    return axios.get('/admin/export-file', { params, responseType: 'blob' });
}

export const getEyeTests = (params) => {
    return axios.get('/admin/eye-test-report', { params });
}

export const downloadEyeTestReport = (params) => {
    return axios.get('/admin/export-eye-test-report', { params, responseType: 'blob' });
}

export const downloadInventory = (params) => {
    return axios.get('/admin/export-inventory', { params, responseType: 'blob' })
}

export const bulkUpdateInventory = (data) => {
    return axios.post('/admin/bulk-update-inventory', data);
}

export const getStoreUtilityData = () => {
    return axios.get("/store/utility1");
}

export const getHolidays = () => {
    return axios.get('/store/holidays');
}

export const addHoliday = (data) => {
    return axios.post('/store/holidays', data);
}

export const getHoliday = (id) => {
    return axios.get(`/store/holidays/${id}`);
}

export const editHoliday = (data) => {
    return axios.put('/store/holidays', data);
}

export const getTimings = () => {
    return axios.get('/store/timing');
}

export const getTiming = (id) => {
    return axios.get(`/store/timing/${id}`);
}

export const editTiming = (id, data) => {
    return axios.put(`/store/timing/${id}`, data);
}

export const addTiming = (data) => {
    return axios.post('/store/timing', data);
}

export const getStoreDetails = (id) => {
    return axios.get(`/store/store-detail/${id}`)
}

export const addStoreDetails = (data) => {
    return axios.post('/store/store-detail', data);
}

export const editStoreDetails = (id, data) => {
    return axios.put(`/store/store-detail/${id}`, data);
}

export const addAvailability = (data) => {
    return axios.post('/store/store-availability', data);
}

export const addStoreServices = (data) => {
    return axios.post('/store/service_add_store', data);
}

export const getServices = () => {
    return axios.get('/store/service');
}

export const getService = (id) => {
    return axios.get(`/store/service/${id}`);
}

export const createService = (data) => {
    return axios.post('/store/service', data);
}

export const editService = (id, data) => {
    return axios.put(`/store/service/${id}`, data);
}

//editClaimUser
export const editClaimUser = (id, data) => {
    return axios.put(`/admin/claim-user/${id}`, data);
}

export const getAvailability = (store_id) => {
    return axios.get(`/store/store-availability/${store_id}`);
}

export const getClaimUser = () => {
    return axios.get(`/admin/claim-user/`);
}

export const editAvailablity = (store_id, data) => {
    return axios.put(`/store/store-availability/${store_id}`, data)
}

export const getStoreServices = (store_id) => {
    return axios.get(`/store/store-services/${store_id}`);
}

export const updateStoreServies = (store_id, data) => {
    return axios.put(`/store/store-services/${store_id}`, data);
}