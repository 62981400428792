import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Layout from "./layouts";
import PrivateRoutes from "./routes/PrivateRoutes";
import Loader from "./components/Loader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./assets/scss/main.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    fontFamily: "Nunito, sans-serif",
    h1: {
      fontSize: "30px",
      fontWeight: "700",
    },
    h2: {
      fontSize: "20px",
    },
    font22: {
      fontSize: "22px",
    },
    font20: {
      fontSize: "20px",
    },
    font16: {
      fontSize: "16px",
    },
    font14: {
      fontSize: "14px",
    },
    font12: {
      fontSize: "12px",
    },
    body: {
      fontSize: "14px",
    },
  },
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: "#4596F3",
    },
    secondary: {
      main: "#6c757d",
    },
    dark: {
      main: "#000",
    },
  },
});

const LogIn = lazy(() => import("./pages/LogIn"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Retailers = lazy(() => import("./pages/Retailers"));
const AddRetailer = lazy(() => import("./components/retailer/AddRetailer"));
const ViewRetailer = lazy(() => import("./components/retailer/ViewRetailer"));
const Coupons = lazy(() => import("./pages/Coupons"));
const AddCoupon = lazy(() => import("./components/coupon/AddCoupon"));
const OrderReport = lazy(() => import("./pages/OrderReport"));
const Notifications = lazy(() => import("./pages/Notifications"));
const CreateNotification = lazy(() => import("./components/CreateNotification"));
const UserFeedback = lazy(() => import("./pages/UserFeedback"));
const ViewCoupon = lazy(() => import("./components/coupon/ViewCoupon"));
const KnowledgeBase = lazy(() => import("./pages/KnowledgeBase"));
const AddKnowledgeVideo = lazy(() => import("./components/AddKnowledgeVideo"));
const OrderView = lazy(() => import('./pages/OrderView'));
const EyeTestReport = lazy(() => import('./pages/EyeTestReport'));
const StoreDetails = lazy(() => import("./pages/StoreDetails"));
const AddStoreDetails = lazy(() => import("./components/AddStoreDetails"));
const ViewStoreDetail = lazy(() => import("./components/ViewStoreDetail"));
const Holidays = lazy(() => import('./pages/Holidays'));
const AddHoliday = lazy(() => import('./components/AddHoliday'));
const Timings = lazy(() => import('./pages/Timings'));
const AddTiming = lazy(() => import('./components/AddTiming'));
const AddAvailability = lazy(() => import('./components/AddAvailability'));
const AddStoreService = lazy(() => import('./components/AddStoreService'));
const Services = lazy(() => import('./pages/Services'));
const AddService = lazy(() => import('./components/AddService'));
const ClaimCustomerDetail = lazy(() => import('./components/claim/ClaimCustomerDetail'));
const ClaimReport = lazy(() => import('./components/claim/ClaimReport'));
const RetryClaimReport = lazy(() => import('./components/claim/RetryClaimReport'));

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <BrowserRouter>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<LogIn />} />
                <Route element={<Layout />}>
                  <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/retailer" element={<Retailers />} />
                    <Route path="/add-retailer" element={<AddRetailer />} />
                    <Route path="/view-retailer" element={<ViewRetailer />} />
                    <Route path="/edit-retailer" element={<AddRetailer />} />
                    <Route path="/coupons" element={<Coupons />} />
                    <Route path="/add-coupon" element={<AddCoupon />} />
                    <Route path="/edit-coupon" element={<AddCoupon />} />
                    <Route path="/view-coupon" element={<ViewCoupon />} />
                    <Route path="/order-report" element={<OrderReport/>} />
                    <Route path="/order" element={<OrderView />} />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route path="/create-notifications" element={<CreateNotification />} />
                    <Route path="/user-feedback" element={<UserFeedback />} />
                    <Route path="eyetest-report" element={<EyeTestReport />} />
                    <Route path="/knowledge-base" element={<KnowledgeBase/>} />
                    <Route path="/add-knowledge-video" element={<AddKnowledgeVideo/>} />
                    {/* <Route path="/store-details" element={ <StoreDetails/>} /> */}
                    <Route path="/add-store-details" element={ <AddStoreDetails />} />
                    <Route path="/edit-store-details" element={ <AddStoreDetails />} />
                    <Route path="/view-store-details" element={<ViewStoreDetail />} />
                    <Route path='/holidays' element={<Holidays />} />
                    <Route path='/add-holiday' element={<AddHoliday />} />
                    <Route path='/edit-holiday' element={<AddHoliday />} />
                    <Route path='/timings' element={<Timings />} />
                    <Route path='/add-timing' element={<AddTiming />} />
                    <Route path='/edit-timing' element={<AddTiming />} />
                    <Route path='/add-availability' element={<AddAvailability />} />
                    <Route path='/edit-availability' element={<AddAvailability />} />
                    <Route path='/add-store-services' element={<AddStoreService />} />
                    <Route path='/edit-store-services' element={<AddStoreService />} />
                    <Route path='/add-service' element={<AddService />} />
                    <Route path='/edit-service' element={<AddService />} />
                    <Route path='/services' element={<Services />} />
                    <Route path="/claim-customer-detail" element={<ClaimCustomerDetail />} />
                    <Route path='/claim-report' element={<ClaimReport />} />
                    <Route path='/retry-claim-report' element={<RetryClaimReport />} />

                  </Route>
                </Route>
              </Routes>
            </Suspense>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
