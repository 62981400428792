import React, { useState } from 'react';
import SidebarLogo from './../assets/images/pos-logo.png';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '../assets/images/dashboard.svg';
import RetailerIcon from '../assets/images/retailer.svg';
import CouponIcon from '../assets/images/coupon.svg';
import UserIcon from '../assets/images/Profile.svg';
import SettingIcon from '../assets/images/Setting.svg';
import LogoutIcon from '../assets/images/Logout.svg';
import './../assets/scss/header.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

function Sidebar() {
  const location = useLocation();
  const [submenusVisibility, setSubmenusVisibility] = useState({
    'claim-customer-detail': false,
    'store-details': false
  });

  const toggleSubmenu = (menuKey) => {
    setSubmenusVisibility({
      ...submenusVisibility,
      [menuKey]: !submenusVisibility[menuKey],
    });
  };

  return (
    <>
      <Link to="/"> <img src={SidebarLogo} alt="POS EyeMyeye" /></Link>
      <nav className='retailerSidebar'>
        <ul>
          <li className={`${location.pathname === '/dashboard' ? 'active' : ''} `}>
            <Link to="/dashboard">
              <img src={DashboardIcon} alt="Eye checkup" />
              Dashboard
            </Link>
          </li>
          <li className={`${location.pathname === '/retailer' ? 'active' : ''} `}>
            <Link to="/retailer">
              <img src={RetailerIcon} alt="Stores" />
              Stores
            </Link>
          </li>
          <li className={`${location.pathname === '/coupons' ? 'active' : ''} `}>
            <Link to="/coupons">
              <img src={CouponIcon} alt="Coupon" />
              Coupon
            </Link>
          </li>
          <li className={`${location.pathname === '/user-feedback' ? 'active' : ''} `}>
            <Link to="/user-feedback">
              <img src={CouponIcon} alt="Eye Test Report" />
              User Feedback
            </Link>
          </li>
          <li className={`${location.pathname === '/eyetest-report' ? 'active' : ''} `}>
            <Link to="/eyetest-report">
              <img src={CouponIcon} alt="Eye Test Report" />
              Eye Test Report
            </Link>
          </li>
          <li className={`${location.pathname === '/notifications' ? 'active' : ''} `}>
            <Link to="/notifications">
              <img src={UserIcon} alt="Notification" />
              Notification
            </Link>
          </li>
          {/* <li className={`${location.pathname === '/knowledge-base' ? 'active' : ''} `}>
            <Link to="/knowledge-base">
              <img src={RetailerIcon} alt="Knowledge Base" />
              Knowledge Base
            </Link>
          </li> */}
          <li className={`${location.pathname === '/order-report' ? 'active' : ''} `}>
            <Link to="/order-report">
              <img src={SettingIcon} alt="Order" />
              Order Report
            </Link>
          </li>

          <li className={`${location.pathname === '/timings' || location.pathname === '/holidays' || location.pathname === '/services' ? 'active' : ''} `}>
            <div>
              <Link to="">
                <img src={CouponIcon} alt="Claim Customer Detail" />
                Store Details
              </Link>
              <span onClick={() => toggleSubmenu('store-details')}><FontAwesomeIcon icon={faAngleDown} /></span>
            </div>

            {/* sub menu */}
            {submenusVisibility['store-details'] && (
              <ul className="submenu">
                <li>
                  <Link to="/holidays">Holidays</Link>
                </li>
                <li>
                  <Link to="/timings">Timings</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
              </ul>
           )}
          </li>

          <li className={`${location.pathname === '/claim-customer-detail' ? 'active' : ''} `}>
            <div>
              <Link to="/claim-customer-detail">
                <img src={CouponIcon} alt="Claim Customer Detail" />
                Claim
              </Link>
              <span onClick={() => toggleSubmenu('claim-customer-detail')}><FontAwesomeIcon icon={faAngleDown} /></span>
            </div>

            {/* sub menu */}
            {submenusVisibility['claim-customer-detail'] && (
            <ul className="submenu">
              <li>
                <Link to="/claim-report">Claim report</Link>
              </li>
              <li>
                <Link to="/retry-claim-report">Retry Claim Report</Link>
              </li>
            </ul>
           )}
          </li>
        </ul>
        <Link to="/" className='logout-wrap'>
          <img src={LogoutIcon} alt="Log out" />
          Log out
        </Link>
      </nav>
    </>
  )
}

export default Sidebar;
