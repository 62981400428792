const ERROR = 'ERROR';
const SUCCESS = 'SUCCESS';
const RESET_MESSAGE = 'RESET_MESSAGE';
const UPDATE_HEADER_STATE = 'UPDATE_HEADER_STATE';
const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';

export {
    ERROR,
    SUCCESS,
    RESET_MESSAGE,
    UPDATE_HEADER_STATE,
    UPDATE_CAMPAIGN
}